<template>
  <Dialog header="选择杀虫剂" @hide="display=false" style="width: 40vw" v-model:visible="display">
    <DataTable :value="list" :scrollable="true" :row-hover="true" v-model:selection="selectedList">
      <template #empty>
        没有发现数据
      </template>
      <column selectionMode="multiple" style="width: 100px"></column>
      <Column field="name" header="名称"></Column>
      <Column field="mfr" header="厂家/供应商/渠道"></Column>

    </DataTable>
    <Paginator :rows="page.size" :totalRecords="page.total"
               @page="changePage"
               template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="共{totalRecords} 条记录">
      <template #right>
        <label>跳至&nbsp;&nbsp;</label>
        <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                     inputStyle="width: 40px;"/>
        <label>&nbsp;&nbsp;页</label>
      </template>
    </Paginator>
    <template #footer>
      <Button @click="submit">确认选择</Button>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'selectInsecticide',
  data() {
    return {
      selectedList: [],
      list: [],
      display: null,
      page: {
        size: 10,
        total: '',
        current: 1,
      },
    };
  },
  methods: {
    init() {
      this.display = true;
      this.getList();
    },
    getList() {
      let m = this;
      this.$http('/insecticide/getPage', 'get', {
        'current': this.page.current, 'size': this.page.size,
      }, function(res) {
        m.page.total = res.data.total;
        m.list = res.data.records;
      });
    },
    changePage(page) {
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList();
    },
    submit() {
      if (this.selectedList && this.selectedList.length > 0) {
        this.$emit('selectedResult', this.selectedList);
        this.display = false;
      } else {
        this.$toast.add({severity: 'warn', summary: '至少选择一项！', life: 3000})
      }
    },
  },
};
</script>

<style scoped>

</style>